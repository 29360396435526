




import { Component, Vue } from "vue-property-decorator";
import { dispatchGetResult } from "@/store/main/actions";

@Component
export default class ResultStart extends Vue {
  public async mounted() {
    dispatchGetResult(this.$store, +this.$route.params.id);
  }
}
